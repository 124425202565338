import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import {
  CreateHealthMemberPayload,
  HealthMemberFilterParams,
  MembroEquipeSaude,
  UpdateHealthMemberPayload,
} from 'src/app/models/membro-equipe-saude.model';
import { environment } from 'src/environments/environment';
import { ImportService } from '../shared/interfaces/import.interface';

@Injectable({
  providedIn: 'root',
})
export class HealthMemberService implements ImportService {
  API = `${environment.backendUrl}membroEquipeSaude`;
  constructor(private http: HttpClient, private authService: AuthService) { }

  async import(file): Promise<Response> {
    try {
      const headers = await this.authService.buildAPIAuthHeader(file.type);
      const uploadedFile = new FormData();
      uploadedFile.append('file', file);
      return await this.http.post<Response>(this.API + '/importar', uploadedFile, { headers }).toPromise();
    } catch (error) {
      throw error;
    }
  }

  async getHealthMemberInfo(): Promise<MembroEquipeSaude> {
    try {
      const headers = await this.authService.buildAPIAuthHeader();
      return this.http
        .get<MembroEquipeSaude>(
          this.API + `/usuario/${this.authService.getUserData().uid}`,
          {
            headers,
          }
        )
        .toPromise();
    } catch (error: any) {
      throw error.error.error;
    }
  }

  async registerHealthMember(payload: CreateHealthMemberPayload): Promise<any> {
    try {
      const headers = await this.authService.buildAPIAuthHeader();
      return this.http.post(this.API, payload, { headers }).toPromise();
    } catch (error: any) {
      throw error.error.error;
    }
  }

  async getHealthMember(healthMemberId: string): Promise<MembroEquipeSaude> {
    try {
      const headers = await this.authService.buildAPIAuthHeader();
      return this.http
        .get<MembroEquipeSaude>(`${this.API}/${healthMemberId}`, { headers })
        .toPromise();
    } catch (error) {
      throw Error('Load health member detail failed!');
    }
  }

  async loadHealthMembers(
    queryParams?: HealthMemberFilterParams
  ): Promise<{ count: number; healthMembers: Array<MembroEquipeSaude> }> {
    try {
      const headers = await this.authService.buildAPIAuthHeader();
      const options = { headers, params: {} };

      if (queryParams) {
        let params = new HttpParams();

        if (queryParams.filterName.length) {
          params = params.set('filterName', queryParams.filterName);
        }

        if (queryParams.filterCPF.length) {
          params = params.set(
            'filterCPF',
            queryParams.filterCPF.replace(/\.|-/g, '')
          );
        }

        if (queryParams.filterRG.length) {
          params = params.set(
            'filterRG',
            queryParams.filterRG.replace(/\.|-/g, '')
          );
        }

        if (queryParams.page) {
          params = params.set('page', queryParams.page);
        }

        if (queryParams.pageSize) {
          params = params.set('pageSize', queryParams.pageSize + 1);
        }

        options.params = params;
      }
      return this.http
        .get<{ count: number; healthMembers: Array<MembroEquipeSaude> }>(
          this.API,
          options
        )
        .toPromise();
    } catch (error) {
      throw Error('Load health members info failed!');
    }
  }

  async updateHealthMember(
    healthMemberId: string | null,
    updateHealthMember: UpdateHealthMemberPayload
  ): Promise<void> {
    try {
      const headers = await this.authService.buildAPIAuthHeader();
      this.http
        .patch(`${this.API}/${healthMemberId}`, updateHealthMember, { headers })
        .toPromise();
    } catch (error) {
      throw Error('update health member failed!');
    }
  }
}
