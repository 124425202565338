import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { AuthService, ROLES } from 'src/app/auth/auth.service';
import { LoginService } from 'src/app/services/login.service';
import { SignService } from 'src/app/services/user-services/sign.service';
import { environment } from 'src/environments/environment';
import { navRouterSwitch } from '../util/export-functions/nav-router-switch';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  signedInSubscription: Subscription;
  isLoggedIn = false;
  isPatient = false;
  isLoading = false;
  ROLES = ROLES;

  timedOut = false;
  lastPing?: Date = null;

  userInactive: Subject<any> = new Subject();

  constructor(
    private loginService: LoginService,
    public authService: AuthService,
    private signService: SignService,
    private router: Router,
    private idle: Idle,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.isLoggedIn = false;
    this.startIdleTimerConfig();
    this.signedInSubscription = this.loginService.userSignedInWatcher.subscribe(
      () => {
        this.isLoggedIn = this.authService.isAuthenticated();
      }
    );
  }

  ngOnDestroy(): void {
    this.signedInSubscription.unsubscribe();
  }

  startIdleTimerConfig() {
    this.idle.setIdle(environment.loginTimeout);
    this.idle.setTimeout(0);
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.idle.onIdleEnd.subscribe(() => {
      this.resetIdleTimer();
      this.cd.detectChanges();
    });

    this.idle.onIdleStart.subscribe(async () => {
      await this.signOut();
    });

    this.resetIdleTimer();
  }

  resetIdleTimer() {
    this.idle.watch();
    this.timedOut = false;
  }

  navbarRouter(path: string) {
    return this.router.navigate([navRouterSwitch(path)]);
  }

  isNavbarItemActive(itemPath: string): boolean {
    return this.router.url.includes(itemPath);
  }

  get isNotLoggedIn(): boolean {
    return !this.router.url.includes('app');
  }

  get isPatientFullRegistered(): boolean {
    return (
      this.authService.isPatient() &&
      this.authService.isEmailVerified() &&
      !!this.authService.userData &&
      !!this.authService.userData.patient
    );
  }

  async signOut(): Promise<void> {
    try {
      await this.signService.signOut();
      await this.authService.signOut();
    } catch (e) {
      console.error(e);
    }
    await this.router.navigate(['signIn']);
    this.isLoggedIn = false;
  }

  goToCreateForm() {
    this.router.navigate([`/app/form/register`]);
  }

  async gotToPatientVisitDetails() {
    await this.router.navigate([`/app/patient/visits`]);
  }

  get userName(): string {
    if (this.authService.userData) {
      if (this.authService.userData.healthMember) {
        return this.authService.userData.healthMember.nome;
      } else if (this.authService.userData.patient) {
        return this.authService.userData.patient.nome;
      }
    }
    return '';
  }

  get institutionName(): string {
    if (this.authService.userData && this.authService.userData.healthMember) {
      return this.authService.userData.healthMember.nomeUnidadeSaude;
    }
    return '';
  }
}
