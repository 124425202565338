import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminGuardService } from './auth/admin-guard.service';
import { AuthGuardService } from './auth/auth-guard.service';
import { FormGuardService } from './auth/form-guard.service';
import { HealthMemberGuardService } from './auth/health-member-guard.service';
import { IgestoAdminGuardService } from './auth/igesto-admin-guard.service';
import { PrivacyPoliceComponent } from './shared/privacy-police/privacy-police.component';
import { TrialAgreementComponent } from './shared/trial-agreement/trial-agreement.component';

const routes: Routes = [
  {
    path: 'signIn',
    pathMatch: 'full',
    loadChildren: () =>
      import('./pages/tamis-access/tamis-login/tamis-login.module').then(
        (m) => m.TamisLoginModule
      ),
  },
  {
    path: 'signUp',
    loadChildren: () =>
      import('./pages/tamis-access/tamis-register/tamis-register.module').then(
        (m) => m.TamisRegisterModule
      ),
  },
  {
    path: 'user-action',
    loadChildren: () =>
      import('./pages/user-actions/user-actions.module').then(
        (m) => m.UserActionsModule
      ),
  },
  {
    path: 'planosCuidados',
    loadChildren: () =>
      import('./pages/form-fill-management/form-fill-management.module').then(
        (m) => m.FormFillManagementModule
      ),
  },
  {
    path: 'privacy',
    component: PrivacyPoliceComponent,
  },
  {
    path: 'trial_agreement',
    component: TrialAgreementComponent,
  },
  {
    path: 'app',
    children: [
      {
        path: 'patient',
        canActivate: [AuthGuardService],
        loadChildren: () =>
          import('./pages/patient/patient.module').then((m) => m.PatientModule),
      },
      {
        path: 'first-access',
        canActivate: [AuthGuardService],
        loadChildren: () =>
          import(
            './pages/tamis-access/tamis-first-access/tamis-first-access.module'
          ).then((m) => m.TamisFirstAccessModule),
      },
      {
        path: 'health-member',
        canActivate: [AuthGuardService, HealthMemberGuardService],
        loadChildren: () =>
          import('./pages/health-member/health-member.module').then(
            (m) => m.HealthMemberModule
          ),
      },
      {
        path: 'institution',
        canActivate: [AuthGuardService, IgestoAdminGuardService],
        loadChildren: () =>
          import('./pages/institution/institution.module').then(
            (m) => m.InstitutionModule
          ),
      },
      {
        path: 'medicine-prescription',
        canActivate: [AuthGuardService, HealthMemberGuardService],
        loadChildren: () =>
          import(
            './pages/medicine-prescription/medicine-prescription.module'
          ).then((m) => m.MedicinePrescriptionModule),
      },
      {
        path: 'form',
        canActivate: [AuthGuardService, HealthMemberGuardService],
        loadChildren: () =>
          import('./pages/form/form.module').then((m) => m.FormModule),
      },
      {
        path: 'form',
        children: [
          {
            path: '',
            canActivate: [FormGuardService],
            loadChildren: () =>
              import('./pages/form/form.module').then((m) => m.FormModule),
          },
        ],
      },
      {
        path: '',
        canActivate: [AuthGuardService, IgestoAdminGuardService],
        loadChildren: () =>
          import(
            './pages/configuration-management/configuration-management.module'
          ).then((m) => m.ConfigurationManagementModule),
      },
      {
        path: '',
        canActivate: [AuthGuardService, HealthMemberGuardService],
        loadChildren: () =>
          import('./pages/template-management/template-management.module').then(
            (m) => m.TemplateManagementModule
          ),
      },
      {
        path: '',
        canActivate: [AuthGuardService, HealthMemberGuardService],
        loadChildren: () =>
          import(
            './pages/care-plan-management/care-plan-management.module'
          ).then((m) => m.CarePlanManagementModule),
      },
      {
        path: '',
        canActivate: [AuthGuardService, AdminGuardService],
        loadChildren: () =>
          import(
            './pages/admin-configuration-management/admin-configuration-management.module'
          ).then((m) => m.AdminConfigurationManagementModule),
      },
      {
        path: '',
        canActivate: [AuthGuardService, HealthMemberGuardService],
        loadChildren: () =>
          import('./pages/trial-management/trial-management.module').then(
            (m) => m.TrialManagementModule
          ),
      },
    ],
  },
  {
    path: '',
    redirectTo: '/signIn',
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: '/signIn',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
