import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService, ROLES } from './auth.service';

@Injectable({
    providedIn: 'root'
})
export class FormGuardService implements CanActivate {

    constructor(public auth: AuthService, public router: Router) {}
    async canActivate(): Promise<boolean> {
        return true;
    }
}
