import { HttpHeaders, HttpStatusCode } from '@angular/common/http';
import { EventEmitter, Injectable, Injector } from '@angular/core';
import * as firebase from 'firebase/app';
import {
  Auth,
  User,
  getAuth,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
} from 'firebase/auth';
import { environment } from 'src/environments/environment';
import { UserData } from '../models/user.model';
import { HealthMemberService } from '../services/health-member.service';
import { PatientService } from '../services/patient.service';
import {
  SNACK_MESSAGES,
  SNACK_TYPES,
} from '../shared/messages-constants';
import { SnackbarCustomService } from '../services/snackbar-custom.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public auth: Auth;
  public user: User;
  public userObserver: EventEmitter<any> = new EventEmitter(true);
  parsedToken: any;
  public userData: UserData = {};
  private healthMemberService: HealthMemberService;
  private patientService: PatientService;

  constructor(
    private injector: Injector,
    private snackBar: SnackbarCustomService
  ) {
    this.auth = getAuth(
      firebase.initializeApp({
        apiKey: environment.firebaseConfig.apiKey,
        authDomain: environment.firebaseConfig.authDomain,
        projectId: environment.firebaseConfig.projectId,
        storageBucket: environment.firebaseConfig.storageBucket,
        messagingSenderId: environment.firebaseConfig.messagingSenderId,
        appId: environment.firebaseConfig.appId,
      })
    );
    this.auth.languageCode = 'pt-BR';
    this.auth.tenantId = environment.tenantId;
    this.auth.onAuthStateChanged(async (user) => {
      if (user) {
        this.user = user;
        if (!this.isEmailVerified()) {
          this.sendEmailVerification();
        }
        this.userObserver.emit(this.user);
      } else {
        this.userObserver.emit(false);
      }
    });
  }

  signInWithEmailAndPassword = (email: string, password: string) => {
    return signInWithEmailAndPassword(this.auth, email, password);
  };

  sendPasswordResetEmail = (email: string) => {
    return sendPasswordResetEmail(this.auth, email);
  };

  sendEmailVerification = (user = null) => {
    return sendEmailVerification(user ? user : this.user);
  };

  signOut = () => {
    return signOut(this.auth);
  };

  isAuthenticated(): boolean {
    return this.auth.currentUser !== null;
  }

  getUserData(): UserData {
    return this.userData;
  }

  setUserData(data: UserData) {
    this.userData = data;
    if (!this.isPatient()) {
      this.healthMemberService = this.injector.get(HealthMemberService);
      this.healthMemberService
        .getHealthMemberInfo()
        .then((value) => (this.userData.healthMember = value))
        .catch((error) => {
          if (error.status === HttpStatusCode.NotFound) {
            this.snackBar.openSnackBar(
              SNACK_MESSAGES.ERROR.NOT_FOUND_HEALTH_MEMBER,
              SNACK_TYPES.INFO
            );
          } else {
            this.snackBar.openSnackBar(
              SNACK_MESSAGES.ERROR.LOAD_HEALTH_MEMBER,
              SNACK_TYPES.ERROR
            );
          }
          this.signOut().then();
        });
    }

    if (this.isPatient() && this.isEmailVerified()) {
      this.patientService = this.injector.get(PatientService);
      this.patientService
        .getPatientInfo()
        .then((value) => (this.userData.patient = value))
        .catch((error) => {
          if (error.status !== HttpStatusCode.NotFound) {
            this.snackBar.openSnackBar(
              SNACK_MESSAGES.ERROR.LOAD_PATIENT,
              SNACK_TYPES.ERROR
            );
            this.signOut().then();
          }
        });
    }
  }

  isEmailVerified(): boolean {
    return this.auth.currentUser?.emailVerified || false;
  }

  isPatient(): boolean {
    return this.userData.role === ROLES.PATIENT;
  }

  isHealthMember(): boolean {
    return this.userData.role === ROLES.MEMBER;
  }

  isAdmin(): boolean {
    return this.userData.role === ROLES.ADMIN;
  }

  isTamisAdmin(): boolean {
    return this.userData.role === ROLES.IGESTO_ADMIN;
  }

  async refreshUser(): Promise<void> {
    await this.auth.currentUser?.reload();
  }

  async buildAPIAuthHeader(contentType = null): Promise<HttpHeaders> {
    const token = await this.getAPIToken();
    if (contentType) {
      return new HttpHeaders().append('authorization', `Bearer ${token}`);
    }
    return new HttpHeaders()
      .append('authorization', `Bearer ${token}`)
      .append('Content-Type', 'application/json');
  }

  async getAPIToken(): Promise<string> {
    return await this.auth.currentUser?.getIdToken();
  }
}

export const ROLES = {
  ADMIN: 'ADMIN',
  MEMBER: 'MEMBER',
  PATIENT: 'PATIENT',
  IGESTO_ADMIN: 'IGESTO_ADMIN',
};
