<mat-toolbar class="tamis-header" color="primary" *ngIf="isLoggedIn && !authService.isPatient() && !isNotLoggedIn">
  <img src="../../../assets/images/tamis_logo.png" class="logo-header">
  <button mat-icon-button [matMenuTriggerFor]="menu" class="drop-menu">
    <mat-icon fontSet="fa" fontIcon="fa-bars"></mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <button mat-menu-item *ngIf="authService.isHealthMember()" [ngClass]="{'active-nav-item': isNavbarItemActive('patient')}" (click)="navbarRouter('patient')">Pacientes</button>
    <button mat-menu-item *ngIf="authService.isHealthMember()" [ngClass]="{'active-nav-item': isNavbarItemActive('visits')}" (click)="navbarRouter('visits')">Visitas</button>
    <button mat-menu-item [ngClass]="{'active-nav-item': isNavbarItemActive('health-member/list') || isNavbarItemActive('health-member/detail') || isNavbarItemActive('health-member/register')}" (click)="navbarRouter('member')">Membros</button>
    <button mat-menu-item *ngIf="authService.isHealthMember()" [ngClass]="{'active-nav-item': isNavbarItemActive('medicine')}" (click)="navbarRouter('medicine')">Prescrições
      Médicas</button>
    <button mat-menu-item *ngIf="authService.isHealthMember()" [ngClass]="{'active-nav-item': isNavbarItemActive('form')}" (click)="navbarRouter('form')">Questionários</button>
    <button mat-menu-item *ngIf="authService.isHealthMember()" [ngClass]="{'active-nav-item': isNavbarItemActive('care-plan')}" (click)="navbarRouter('carePlan')">Plano de cuidados</button>

    <!-- to be switched back -->
    <!-- <button mat-menu-item *ngIf="authService.isHealthMember()" [ngClass]="{'active-nav-item': isNavbarItemActive('trial')}" (click)="navbarRouter('trial')">Trial</button> -->

    <button mat-menu-item *ngIf="authService.isTamisAdmin()" [ngClass]="{'active-nav-item': isNavbarItemActive('institution')}" (click)="navbarRouter('institution')">Instituição</button>
    <button mat-menu-item *ngIf="authService.isTamisAdmin()" [ngClass]="{'active-nav-item': isNavbarItemActive('configuration')}" (click)="navbarRouter('configuration')">Configuração</button>
    <button mat-menu-item *ngIf="authService.isAdmin()" [ngClass]="{'active-nav-item': isNavbarItemActive('admin-configuration')}" (click)="navbarRouter('adminConfigurations')">Configuração</button>

    <!-- to be switched back -->
    <!-- <button mat-menu-item *ngIf="authService.isHealthMember()" [ngClass]="{'active-nav-item': isNavbarItemActive('register-template')}" (click)="navbarRouter('memberConfigurations')">Configurações</button> -->

  </mat-menu>
  <button mat-button *ngIf="authService.isHealthMember()" [ngClass]="{'active-nav-item': isNavbarItemActive('patient')}" (click)="navbarRouter('patient')"
    class="navbar-item">Pacientes</button>
  <button mat-button *ngIf="authService.isHealthMember()" [ngClass]="{'active-nav-item': isNavbarItemActive('visits')}" (click)="navbarRouter('visits')"
    class="navbar-item">Visitas</button>
  <button mat-button [ngClass]="{'active-nav-item': isNavbarItemActive('health-member/list') || isNavbarItemActive('health-member/detail') || isNavbarItemActive('health-member/register')}" (click)="navbarRouter('member')" class="navbar-item">Membros</button>
  <button mat-button *ngIf="authService.isHealthMember()" [ngClass]="{'active-nav-item': isNavbarItemActive('medicine')}" (click)="navbarRouter('medicine')"
    class="navbar-item">Prescrições Médicas</button>
  <button mat-button *ngIf="authService.isHealthMember()" [ngClass]="{'active-nav-item': isNavbarItemActive('form')}" (click)="navbarRouter('form')"
    class="navbar-item">Questionários</button>
  <button mat-button *ngIf="authService.isHealthMember()" [ngClass]="{'active-nav-item': isNavbarItemActive('care-plan')}" (click)="navbarRouter('carePlan')"
    class="navbar-item">Plano de cuidados</button>

    <!-- to be switched back -->
  <!-- <button mat-button *ngIf="authService.isHealthMember()" [ngClass]="{'active-nav-item': isNavbarItemActive('trial')}" (click)="navbarRouter('trial')"
    class="navbar-item">Trial</button> -->

  <button mat-button *ngIf="authService.isTamisAdmin()" [ngClass]="{'active-nav-item': isNavbarItemActive('institution')}" (click)="navbarRouter('institution')"
    class="navbar-item">Instituição</button>
  <button mat-button *ngIf="authService.isTamisAdmin()" [ngClass]="{'active-nav-item': isNavbarItemActive('configuration')}" (click)="navbarRouter('configuration')"
    class="navbar-item">Configuração</button>
  <button mat-button *ngIf="authService.isAdmin()" [ngClass]="{'active-nav-item': isNavbarItemActive('admin-configuration')}" (click)="navbarRouter('adminConfigurations')"
    class="navbar-item">Configurações</button>

    <!-- to be switched back -->
  <!-- <button mat-button *ngIf="authService.isHealthMember()" [ngClass]="{'active-nav-item': isNavbarItemActive('register-template')}" (click)="navbarRouter('memberConfigurations')"
    class="navbar-item">Configurações</button> -->

  <div class="end-header">
    <mat-spinner *ngIf="isLoading" class="spinner-center" diameter="50" color="white" mode="indeterminate">
    </mat-spinner>
    <div class="institution-label">{{institutionName}}</div>
    <app-logged-user-info
      *ngIf="!isLoading"
      (onSignOut)="signOut()"
      [userName]="userName"
      [institutionName]="institutionName"
    ></app-logged-user-info>
  </div>
</mat-toolbar>

<!-- start Patient menu -->
<mat-toolbar class="tamis-header" color="primary" *ngIf="isLoggedIn && authService.isPatient() && !isNotLoggedIn">
  <img src="../../../assets/images/tamis_logo.png" class="logo-header">
  <button mat-icon-button [matMenuTriggerFor]="menu" class="drop-menu">
    <mat-icon fontSet="fa" fontIcon="fa-bars"></mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <button mat-menu-item [ngClass]="{'active-nav-item': isNavbarItemActive('user')}" (click)="navbarRouter('user')" *ngIf="isPatientFullRegistered">Dados Cadastrais</button>
    <button mat-menu-item *ngIf="isPatientFullRegistered" [ngClass]="{'active-nav-item': isNavbarItemActive('patient')}" (click)="gotToPatientVisitDetails()">Visitas</button>
  </mat-menu>
  <button mat-button [ngClass]="{'active-nav-item': isNavbarItemActive('user')}"  (click)="navbarRouter('user')" class="navbar-item" *ngIf="isPatientFullRegistered">Dados
    Cadastrais</button>
  <button mat-button *ngIf="isPatientFullRegistered" (click)="gotToPatientVisitDetails()"
    class="navbar-item">Visitas</button>
  <div class="end-header">
    <mat-spinner *ngIf="isLoading" class="spinner-center" diameter="50" color="white" mode="indeterminate">
    </mat-spinner>
    <div class="institution-label">{{institutionName}}</div>
    <app-logged-user-info
      *ngIf="!isLoading"
      (onSignOut)="signOut()"
      [userName]="authService.userData.patient?.nome"
    ></app-logged-user-info>
  </div>
</mat-toolbar>
<!-- end Patient menu -->