import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService, ROLES } from './auth.service';

@Injectable({
    providedIn: 'root'
})
export class IgestoAdminGuardService implements CanActivate {

    constructor(public auth: AuthService, public router: Router) {}
    async canActivate(): Promise<boolean> {
        const isUserIgestoAdmin = this.auth.getUserData().role === ROLES.IGESTO_ADMIN;
        if (!isUserIgestoAdmin) {
            await this.router.navigate(['signIn']);
            return false;
        }
        return true;
    }

}
