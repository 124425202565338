import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService, ROLES } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class AdminGuardService implements CanActivate {
  constructor(public auth: AuthService, public router: Router) {}
  async canActivate(): Promise<boolean> {
    const idTokenResult = await this.auth.user?.getIdTokenResult(true);
    const {role}: any = idTokenResult?.claims;

    const isUserAAdmin =
      role === ROLES.IGESTO_ADMIN ||
      role === ROLES.ADMIN;
    
      if (!isUserAAdmin) {
      await this.router.navigate(['signIn']);
      return false;
    }
    return true;
  }
}
